import { StatusLabel } from '@/common/model/statusLabel';
import { ScheduleState } from '../model/product/enum';

export const ScheduleStatusLabels = [
  {
    value: ScheduleState.EDITING,
    label: '등록중',
    variant: 'default',
  },
  {
    value: ScheduleState.OPENED,
    label: '판매중',
    variant: 'primary',
  },
  {
    value: ScheduleState.CLOSED,
    label: '신청마감',
    variant: 'primary',
  },
  {
    value: ScheduleState.FINISHED,
    label: '일정종료',
    variant: 'danger',
  },
  {
    value: ScheduleState.PAUSED,
    label: '판매 일시중지',
    variant: 'danger',
  },
  {
    value: ScheduleState.CANCELED,
    label: '취소',
    variant: 'warning',
  },
  {
    value: ScheduleState.SOLD_OUT,
    label: '품절',
    variant: 'warning',
  },
];

export const getScheduleStatusLabel = (state: ScheduleState): StatusLabel => {
  const label = ScheduleStatusLabels.find(i => i.value == state);
  if (label) {
    return {
      text: label.label,
      color: label.variant,
      extraText: '',
    };
  }
  return {
    text: '등록중',
    color: 'default',
    extraText: '',
  };
};
