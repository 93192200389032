































































































































import Vue from 'vue';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Table,
  TableColumn,
} from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import Instant from '@/components/Labels/Instant.vue';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { INSPECTION_LIST_QUERY } from '../graphql/query';
import {
  InspectionFilter,
  InspectionFilterQuery,
  InspectionTermSearchType,
} from '../model/InspectionFilter';
import InspectionModeLabel from './controls/InspectionModeLabel.vue';
import {
  InspectionState,
  InspectionItemState,
  InspectionMode,
} from '../model/enum';
import InspectionStatusLabel from './controls/InspectionStatusLabel.vue';
import { getDomainName } from '@/env';

//TODO FIXME: 중복 변수 공통화 관리
enum ProductState {
  // # 등록중
  EDITING = 'EDITING',
  // # 검수신청
  APPLIED = 'APPLIED',
  // # 검수중
  INSPECTING = 'INSPECTING',
  // # 검수반려
  REJECTED = 'REJECTED',
  // # 판매대기
  READY = 'READY',
  // # 판매중
  SALE = 'SALE',
  // # 판매중지
  SUSPENDED = 'SUSPENDED',
  // # 품절
  SOLD_OUT = 'SOLD_OUT',
  // # 일시중지
  PAUSED = 'PAUSED',
  // # 판매종료
  CLOSED = 'CLOSED',
  // # 운영 종료(호스트 계약 종료 등등)
  TERMINATED = 'TERMINATED',
  // # 삭제
  DELETED = 'DELETED',
}

interface SearchParam {
  page?: number;
  size?: number;
  filter?: InspectionFilter;
}

interface Inspection {
  id: string;
  product: Product;
  items: {
    id: string;
    worker?: { id: string; nickname: string };
    status: InspectionItemState;
  }[];
  mode: InspectionMode;
  status: InspectionState;
  createdAt: number;
  completedAt?: number;
}

interface Product {
  id: string;
  status: ProductState;
  title: string;
  host: { id: string; name: string };
}

interface Connection<T> {
  totalCount: number;
  edges: Edge<T>[];
}

interface Edge<T> {
  node: T;
}

export default Vue.extend({
  name: 'InspectionSearchList',
  // props: {
  //   filter: {
  //     type: Object,
  //   },
  // },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    Instant,
    Spinner,
    InspectionModeLabel,
    InspectionStatusLabel,
  },

  data() {
    return {
      loading: 0,
      inspections: [],
      totalCount: 0,
      size: 10,
      fripUrl: getDomainName(),
    };
  },
  computed: {
    numberOfPages(): number {
      return Math.ceil(this.totalCount / this.size);
    },
    filter(): InspectionFilter {
      const query = this.$route.query as InspectionFilterQuery;

      return {
        productIds: query.productIds || null,
        inspectionIds: query.inspectionIds || null,
        titleLike: query.titleLike || null,
        hostId: query.hostId || null,
        statusIn: query.statusIn ? (query.statusIn as InspectionState[]) : null,
        modeIn: query.modeIn ? (query.modeIn as InspectionMode[]) : null,
        termWithType:
          query.termType && query.startedAt && query.endedAt
            ? {
                type: query.termType as InspectionTermSearchType,
                term: {
                  startedAt: Number(query.startedAt),
                  endedAt: Number(query.endedAt),
                },
              }
            : null,
      };
    },
  },
  methods: {
    linkGen(pageNum: number): any {
      return {
        query: {
          ...this.$route.query,
          page: pageNum >= 1 ? pageNum : undefined,
        },
      };
    },
    getWorkerName(inspection: Inspection) {
      return inspection.items[0]?.worker?.nickname || '';
    },
    onClickHistory(inspection: Inspection) {
      this.$emit('updatedFilter', {
        productIds: [inspection.product.id],
      });
      this.$route.query.page = '1';
    },
    isSaleProduct(status: ProductState) {
      return status === ProductState.SALE;
    },
  },
  apollo: {
    inspections: {
      query: INSPECTION_LIST_QUERY,
      variables(): SearchParam {
        return {
          page: Number(this.$route.query.page) || 1,
          size: this.size,
          filter: this.filter,
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          product: { inspections: Connection<Inspection> };
        }>
      ) {
        this.totalCount = result.data.product.inspections.totalCount;
      },
      update: (data: { product: { inspections: Connection<Inspection> } }) =>
        data.product.inspections.edges.map(edge => edge.node),
    },
  },
});
