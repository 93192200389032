




import Vue from 'vue';
import { InspectionState } from '../../model/enum';

const inspectionStates = [
  {
    value: InspectionState.APPROVED,
    label: '검수승인',
    variation: 'primary',
  },
  {
    value: InspectionState.APPLIED,
    label: '검수신청',
    variation: 'success',
  },
  {
    value: InspectionState.INSPECTING,
    label: '검수중',
    variation: 'secondary',
  },
  {
    value: InspectionState.REJECTED,
    label: '검수반려',
    variation: 'danger',
  },
  {
    value: InspectionState.PAUSED,
    label: '검수중지',
    variation: 'danger',
  },
  {
    value: InspectionState.CANCELED,
    label: '검수취소',
    variation: 'danger',
  },
  {
    value: InspectionState.DELETED,
    label: '삭제',
    variation: 'danger',
  },
];

export default Vue.extend({
  name: 'inspectionStatesLabel',
  props: {
    status: {
      type: String,
    },
  },
  computed: {
    variation: function () {
      return inspectionStates.find(i => i.value.toString() == this.status)
        ?.variation;
    },
    label: function () {
      return inspectionStates.find(i => i.value.toString() == this.status)
        ?.label;
    },
  },
});
