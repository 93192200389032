











































































import Vue from 'vue';
import ItemSelectList from '../../../product/components/Item/ItemSelectList.vue';
import {
  ScheduleParam,
  ItemParam,
} from '../../../product/model/product/param/productSaleInfoParam';
import { deepCopy } from '@/common/util/deepCopy';
import { getScheduleStatusLabel } from '../../../product/util/getScheduleStatusLabel';
import { ScheduleState } from '../../../product/model/product/enum';
import { DAY_MILLISECOND } from '@/common/util/day/getConstants';
import { StatusLabel } from '@/common/model/statusLabel';
import Instant from '@/components/Labels/Instant.vue';
import InlineText from '@/components/Forms/InlineText.vue';

export default Vue.extend({
  name: 'ScheduleForm',
  components: {
    ItemSelectList,
    Instant,
    InlineText,
  },
  props: {
    schedule: {
      type: Object,
    },
    items: {
      type: Array,
    },
    inventoryTargetType: {
      type: String,
    },
    isSale: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        id: this.schedule.id,
        appliedAllItems: this.schedule.appliedAllItems,
        title: this.schedule.title,
        saleTerm: this.schedule.saleTerm,
        term: {
          startedAt: this.schedule.term.startedAt,
          endedAt: this.schedule.term.startedAt + this.schedule.term.duration,
          duration: this.schedule.term.duration,
        },
        itemParamIds: this.schedule.itemParamIds,
        minimumQuota: this.schedule.minimumQuota,
        quota: this.schedule.quota,
        planParamId: this.schedule.planParamId,
        status: this.schedule.status,
      },
      planFormModal: false,
      itemSelectModal: false,
      itemList: this.items as ItemParam[],
    };
  },
  computed: {
    isRepeatSchedule(): boolean {
      return this.schedule.planParamId ? true : false;
    },
    selectedItems(): ItemParam[] {
      return this.itemList.filter((item: ItemParam) =>
        this.form.itemParamIds.includes(item.id)
      );
    },
    canEdit(): boolean {
      return (
        !this.schedule.status || this.schedule.status === ScheduleState.EDITING
      ); // 스케줄의 상태가 최초 등록이거나 등록중 상태일 경우 수정가능
    },
    statusLabel(): StatusLabel {
      return getScheduleStatusLabel(this.form.status);
    },
  },
  created() {
    this.itemList.forEach(item => {
      item.checked = false;
      if (this.form.itemParamIds.includes(item.paramId)) {
        item.checked = true;
      }
    });
  },
  methods: {
    saveSchedule() {
      this.updateSchedule();
    },
    createSchedule() {
      // 개별일정에는 planParamId가 존재하지 않는다!
      const newSchedule: ScheduleParam = {
        paramId: `schedule${Math.random()}`,
        title: this.form.title,
        appliedAllItems: this.form.appliedAllItems,
        saleTerm: this.form.saleTerm,
        term: {
          startedAt: this.form.term.startedAt,
          endedAt: this.form.term.endedAt,
          duration: this.form.term.endedAt - this.form.term.startedAt,
        },
        minimumQuota: this.form.minimumQuota,
        quota: this.form.quota,
        itemParamIds: this.selectedItems.map(item => item.paramId),
        maximumPurchasableCount: this.schedule.maximumPurchasableCount,
        edited: false,
      };
      this.$emit('addSchedule', newSchedule);
    },
    showUpdatePlanForm() {
      this.$emit('showUpdatePlanForm', this.form.planParamId);
    },
    updateSchedule() {
      // TODO: 기본 schedule과 다른지 체크해야함
      if (
        this.schedule.term.startedAt - this.schedule.saleTerm.endedAt <=
        DAY_MILLISECOND
      ) {
        console.log(
          this.schedule.term.startedAt - this.schedule.saleTerm.endedAt
        );
      }
      const schedule: ScheduleParam = {
        id: this.schedule.id, // 만약 상품 수정중이라면 schdule에 아이디가 존재
        appliedAllItems: this.form.appliedAllItems,
        paramId: this.schedule.paramId,
        title: this.form.title,
        saleTerm: this.form.saleTerm,
        term: {
          startedAt: this.form.term.startedAt,
          endedAt: this.form.term.endedAt,
          duration: this.form.term.endedAt - this.form.term.startedAt,
        },
        minimumQuota: this.form.minimumQuota,
        quota: this.form.quota,
        itemParamIds: this.form.appliedAllItems
          ? []
          : this.selectedItems.map(item => item.paramId),
        planParamId: this.schedule.planParamId,
        maximumPurchasableCount: this.schedule.maximumPurchasableCount,
        status: this.schedule.status,
        edited: true,
      };
      this.$emit('update', schedule);
    },
    pauseSchedule() {
      this.$modal.show(
        {
          title: '일정의 판매를 일시 중지 합니다.',
          message: '일정의 판매를 일시중지 합니다.',
          showCancelButton: true,
        },
        () => {
          this.$emit('pause', this.form.id);
        }
      );
    },
    openSchedule() {
      this.$modal.show(
        {
          title: '일정의 재판매 합니다.',
          message: '일정의 판매를 시작합니다.',
          showCancelButton: true,
        },
        () => {
          this.$emit('open', this.form.id);
        }
      );
    },
    deleteSchedule() {
      this.$emit('delete', this.schedule.paramId);
    },
    closeScheduleForm() {
      this.$emit('close');
    },
    showItemSelectModal() {
      this.itemSelectModal = true;
    },
    closeItemSelectModal() {
      this.itemSelectModal = false;
    },
  },
});
