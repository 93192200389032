





























































import Vue from 'vue';
import ScheduleCardTypeDescription from '../../../product/components/Schedule/ScheduleCardTypeDescription.vue';
import ScheduleDay from './ScheduleDay.vue';
import ScheduleForm from './ScheduleForm.vue';
import Instant from '@/components/Labels/Instant.vue';
import {
  PlanParam,
  ScheduleParam,
  ScheduleTemplate,
} from '../../../product/model/product/param/productSaleInfoParam';
import { deepCopy } from '@/common/util/deepCopy';
import { NotificationMixin } from '@/common/mixin/notificationMixin';

interface DayInfo {
  date: string;
  day: number;
  time: number;
  schedules: ScheduleParam[];
  isCurrentMonth: boolean;
}

interface FullDate {
  year: number;
  month: number;
  date: number;
  isCurrentMonth: boolean;
}

export default NotificationMixin.extend({
  name: 'ScheduleCalendar',
  mixins: [NotificationMixin],
  components: {
    ScheduleCardTypeDescription,
    ScheduleDay,
    ScheduleForm,
    Instant,
  },
  props: {
    productId: {
      type: String,
    },
    schedulingTerm: {
      type: Object,
    },
    list: {
      type: Array,
    },
    planList: {
      type: Array,
    },
    itemList: {
      type: Array,
    },
    inventoryTargetType: {
      type: String,
    },
    isSale: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      today: this.$moment(),
      selectedDate: new Date().getTime(),
      plans: this.planList as PlanParam[],
      items: deepCopy(this.itemList),
      selectedPlan: {
        appliedAllItems: true,
        minimumQuota: 0,
        name: '',
        overNight: false,
        paramId: '',
        preparatoryPeriod: 0,
        quota: 0,
        targetItemParamIds: [],
        term: {
          startedAt: 0,
          endedAt: 0,
        },
        times: [],
        weeks: [],
      } as PlanParam,
      // schedules: (this.list as ScheduleParam[]) || ([] as any),
      schedules: this.list as ScheduleParam[],
      schedule: {
        paramId: '',
        appliedAllItems: true,
        itemParamIds: [],
        maximumPurchasableCount: 0,
        minimumQuota: 0,
        quota: 0,
        saleTerm: {
          startedAt: 0,
          endedAt: 0,
        },
        term: {
          startedAt: 0,
          endedAt: 0,
          duration: 0,
        },
        title: '',
        type: 'primary',
      } as ScheduleParam,
      showScheduleUpdateForm: false,
      showScheduleTemplateForm: false,
      planFormModal: false,
    };
  },
  methods: {
    dayInfo(week: number, day: number): DayInfo {
      // TODO: 일단은 이렇게 구현했지만 코드 리팩토링 필요함
      let dayOfWeek = (week - 1) * 7 + day - this.startDay; // 날짜를 구함
      const { year, month, date, isCurrentMonth } = this.getFullDate(dayOfWeek);
      const time = this.$moment(`${year}-${month}-${date}`);
      const nextday = time.clone().add('1', 'day');
      return {
        date: time.format('YYYY-MM-DD').toString(),
        day: day,
        time: time.valueOf(),
        schedules: this.schedules.filter(
          (schedule: ScheduleParam) =>
            schedule.term.startedAt >= time.valueOf() &&
            schedule.term.startedAt <= nextday.valueOf() - 1
        ), // TODO: 일단은 당일 일정만 되는데 이것도 개선필요함
        isCurrentMonth: isCurrentMonth,
      };
    },
    getFullDate(dayLabel: number): FullDate {
      let month = this.month;
      let year = this.year;
      let date = dayLabel;
      let isCurrentMonth = true;
      if (date <= 0) {
        // 저번달
        month--;
        if (month === 0) {
          year--;
          month = 12;
        }
        isCurrentMonth = false;
        const lastMonthDays = this.today
          .clone()
          .subtract(1, 'month')
          .daysInMonth();
        date = lastMonthDays + date;
      } else if (date > this.days) {
        // 다음달
        date = date - this.days;
        month = month + 1;
        if (month === 13) {
          year++;
          month = 1;
        }
        isCurrentMonth = false;
      }
      return {
        month: month,
        year: year,
        date: date,
        isCurrentMonth: isCurrentMonth,
      };
    },
    setLastMonth(): void {
      this.today = this.today.clone().subtract(1, 'month');
    },
    setNextMonth(): void {
      this.today = this.today.clone().add(1, 'month');
    },
    showScheduleTemplateFormModal(date?: string) {
      console.log('date: ', date);
      this.selectedDate = date
        ? new Date(date).getTime()
        : new Date().getTime();
      console.log('selectedDate: ', this.selectedDate);
      this.showScheduleTemplateForm = true;
    },
    /**
     * 신청 마감일이 24시간 이전인 경우 item의 모집인원을 1명~1명으로 제한함
     */
    setItemQuota(itemParamIds: string[]) {
      console.log('setItemQuota!');
      this.$emit('setItemQuota', itemParamIds);
    },
    /**
     * paramId를 기준으로 update, delete를 한다.
     */
    showScheduleUpdateFormModal(schedule: ScheduleParam) {
      // TODO: 함수 이름 수정
      this.schedule = schedule;
      this.showScheduleUpdateForm = true;
    },
    deleteSchedule(paramId: string) {
      this.$modal.show(
        {
          title: '정말로 삭제하시겠습니까?',
          message: '일정을 삭제합니다.',
          showCancelButton: true,
          type: 'danger',
        },
        () => {
          const index = this.schedules.findIndex(
            schedule => schedule.paramId === paramId
          );
          if (index >= 0) {
            this.schedules.splice(index, 1);
          }
        }
      );
      this.closeScheduleUpdateFormModal();
    },
    showUpdatePlanForm(planParamId: string) {
      console.log(planParamId);
      const index = this.plans.findIndex(plan => plan.paramId === planParamId);
      this.selectedPlan = this.plans[index];
      this.closeScheduleUpdateFormModal();
      this.showPlanFormModal();
    },
    refetchSchedules() {
      this.$emit('refetch');
    },
    showPlanFormModal() {
      this.planFormModal = true;
    },
    closePlanFormModal() {
      this.planFormModal = false;
    },
    closeScheduleUpdateFormModal() {
      this.showScheduleUpdateForm = false;
    },
    closeScheduleTemplateFormModal() {
      this.showScheduleTemplateForm = false;
    },
  },

  computed: {
    start(): number {
      return this.today.startOf('month').week();
    },
    end(): number {
      return this.today.endOf('month').week();
    },
    weeks(): number {
      if (this.start > this.end) {
        // TODO: 12월인 경우 end가 start보다 작으므로 따로 처리해줘야함 일단 임시로 처리함
        return this.end + 52 - this.start + 1;
      }
      return this.end - this.start + 1;
    },
    startDay(): number {
      return this.today.startOf('month').day();
    },
    year(): number {
      return this.today.year();
    },
    month(): number {
      return this.today.month() + 1;
    },
    days(): number {
      return this.today.daysInMonth();
    },
  },
  watch: {
    list: {
      immediate: true,
      handler(newList) {
        this.schedules = newList;
      },
    },
    itemList: {
      immediate: true,
      deep: true,
      handler(newList) {
        this.items = deepCopy(newList);
      },
    },
  },
});
