export enum ProductState {
  APPLIED = 'APPLIED',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
  EDITING = 'EDITING',
  INSPECTING = 'INSPECTING',
  SALE = 'SALE',
  SUSPENDED = 'SUSPENDED',
  PAUSED = 'PAUSED',
  READY = 'READY',
  REJECTED = 'REJECTED',
  SOLD_OUT = 'SOLD_OUT',
  TERMINATED = 'TERMINATED',
}

export enum ProductKind {
  NONE = 'NONE', // 기본값(서버에는 없고 초기값)
  GOODS = 'GOODS',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  EVENT = 'EVENT',
  DIGITAL = 'DIGITAL',
}

export enum FripKind {
  COMMON = 'COMMON',
  MEETUP = 'MEETUP',
}

export enum ItemInquiryInputKind {
  MULTI_CHOICE = 'MULTI_CHOICE', // 다중 선택
  SINGLE_CHOICE = 'SINGLE_CHOICE', // 단일 선택
  TEXT = 'TEXT', // 문자열 입력
}

export enum ItemInquiryInputPattern {
  PER_ITEM = 'PER_ITEM', // 구매 아이템별
  PER_ITEM_COUNT = 'PER_ITEM_COUNT', // 구매 아이템 수량별
  PER_PRODUCT = 'PER_PRODUCT', // 구매 상품별
}

export enum SalesType {
  AGENCY = 'AGENCY', // 판매 대행(순액): 수수료가 프립꺼
  PURCHASE = 'PURCHASE', // 사입: 호스트에게 미리 상품을 선구매하여 판매 ! 매출 전부 다 프립꺼
}

export enum TagKind {
  NORMAL = 'NORMAL', // 일반 태그
  PRICE = 'PRICE', // 특가 태그
}

export enum ItemState {
  EDITING = 'EDITING',
  OPENED = 'OPENED',
  SOLD_OUT = 'SOLD_OUT',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',

  TERMINATED = 'TERMINATED',
}

export enum ItemType {
  DIGITAL = 'DIGITAL',
  FRIP = 'FRIP',
  GOODS = 'GOODS',
}

export enum ProductHeaderContentStyle {
  SQUARE = 'SQUARE',
  STANDARD = 'STANDARD',
}

export enum CurriculumStyle {
  DURATION = 'DURATION',
  NONE = 'NONE',
  TIMETABLE = 'TIMETABLE',
  LEGACY = 'LEGACY',
}

export enum LocationKind {
  GATHERING = 'GATHERING', // 집결지
  VENUE = 'VENUE', // 진행지
}

export enum DayOfWeek {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export enum LevelOfDifficulty {
  EASY = 'EASY',
  HIGH = 'HIGH',
  NONE = 'NONE',
  NORMAL = 'NORMAL',
  VERY_EASY = 'VERY_EASY',
}

export enum InventoryTargetType {
  BY_ITEM = 'BY_ITEM', // 아이템별 제한이지만 텍스트는 옵션별 모집인원 제한
  BY_OPTION = 'BY_OPTION',
  BY_SCHEDULE = 'BY_SCHEDULE', // 일정별 모집인원 제한
}

export enum CurrencyCode {
  KRW = 'KRW',
  USD = 'USD',
}

export enum CurriculumKind {
  CURRICULUM = 'CURRICULUM',
  SCHEDULE = 'SCHEDULE',
}

export enum TicketType {
  ONCE = 'ONCE',
  MULTIPLE = 'MULTIPLE',
}

export enum QuotaState {
  RECRUITMENT_BY_ITEM = 'RECRUITMENT_BY_ITEM', // 옵션별 모집인원 제한
  RECRUITMENT_BY_SCHEDULE = 'RECRUITMENT_BY_SCHEDULE', // 일정별 모집인원 제한
  OFFLINE = 'OFFLINE', // 최대 예약 수량
  SHIPPING = 'SHIPPING', // 재고수량
  ONLINE = 'ONLINE', // 수량 한정 판매
}

export enum PriceOperator {
  PLUS = 'PLUS',
  SUB = 'SUB',
}

export enum PriceType {
  ALL = 'ALL',
  RETAIL = 'RETAIL',
  SALE = 'SALE',
}

export enum RepeatSchedule {
  ONCE = 'ONCE',
  DAY = 'DAY',
  WEEK = 'WEEK',
}

export enum ScheduleState {
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  DELETE = 'DELETED',
  EDITING = 'EDITING',
  FINISHED = 'FINISHED',
  OPENED = 'OPENED',
  PAUSED = 'PAUSED',
  SOLD_OUT = 'SOLD_OUT',
}

export enum RefundPolicyType {
  FLEXIBLE = 'FLEXIBLE',
  GOODS = 'GOODS',
  RESTRICTED = 'RESTRICTED',
  SCHEDULED_LOCAL = 'SCHEDULED_LOCAL',
  SCHEDULED_OVERSEAS = 'SCH',
}

export enum CategoryPrefix {
  OFFLINE = '0001',
  ONLINE = '0002',
  SHIPPING = '0003',
}

export enum ProductAction {
  SAVE = 'SAVE',
  INSPECT = 'INSPECT',
  DELETE = 'DELETE',
  OPEN = 'OPEN',
  SOLD_OUT = 'SOLD_OUT',
  PAUSE = 'PAUSE',
  SUSPEND = 'SUSPEND',
  RESUME = 'RESUME',
  CLOSE = 'CLOSE',
  REEDIT = 'REEDIT',
  RETRACT = 'RETRACT',
  TERMINATE = 'TERMINATE',
}

export enum ProductTermSearchType {
  PRODUCT_CREATED = 'PRODUCT_CREATED',
  PRODUCT_UPDATED = 'PRODUCT_UPDATED',
  SALE_END = 'SALE_END',
  SALE_START = 'SALE_START',
}

export enum ScheduleCancellationReason {
  ETC = 'ETC',
  LACK_OF_QUOTA = 'LACK_OF_QUOTA',
  LACK_OF_SUPPLIES = 'LACK_OF_SUPPLIES',
  PERSONAL = 'PERSONAL',
  WEATHER = 'WEATHER',
}
