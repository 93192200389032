var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-card',{attrs:{"title":"검색 결과","subtitle":_vm.totalCount.toString()},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.totalCount > 0)?_c('b-pagination-nav',{staticClass:"mt-3",attrs:{"align":"center","link-gen":_vm.linkGen,"number-of-pages":_vm.numberOfPages,"use-router":""}}):_vm._e()]},proxy:true}])},[(_vm.loading > 0)?_c('spinner'):_c('el-table',{ref:"table",staticClass:"table table-responsive table-flush table-striped align-items-center center-hea",attrs:{"empty-text":"검색 결과가 없습니다.","header-row-class-name":"thead-light","header-cell-class-name":"center-table","data":_vm.inspections}},[_c('el-table-column',{attrs:{"label":"구분","class-name":"text-center","width":80},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('inspection-mode-label',{attrs:{"mode":row.mode}})]}}])}),_c('el-table-column',{attrs:{"label":"검수ID","prop":"id","class-name":"text-center","width":80},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-link',{attrs:{"to":_vm.$wlink.inspection(row.product.id)}},[_vm._v(_vm._s(row.id))])]}}])}),_c('el-table-column',{attrs:{"min-width":200,"label":"상품명","prop":"product.title","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-link',{attrs:{"to":_vm.$wlink.inspection(row.product.id)}},[_vm._v(_vm._s(row.product.title))])]}}])}),_c('el-table-column',{attrs:{"label":"호스트","prop":"product.host.name","class-name":"text-center"}}),_c('el-table-column',{attrs:{"label":"검수상태","class-name":"text-center","width":100},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('inspection-status-label',{attrs:{"status":row.status}})]}}])}),_c('el-table-column',{attrs:{"label":"검수요청일","prop":"createdAt","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.createdAt)?_c('instant',{attrs:{"at":row.createdAt}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"검수종료일","prop":"completedAt","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.completedAt)?_c('instant',{attrs:{"at":row.completedAt}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"검수자","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getWorkerName(row))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"검수내역","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('frip-button',{attrs:{"outline":""},on:{"click":function($event){return _vm.onClickHistory(row)}}},[_vm._v("검수내역")])]}}])}),_c('el-table-column',{attrs:{"label":"더보기","prop":"more","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-dropdown',{staticClass:"dropdown",attrs:{"trigger":"click"}},[_c('frip-button',{attrs:{"outline":""}},[_vm._v("더보기")]),_c('el-dropdown-menu',{staticClass:"dropdown-menu dropdown-menu-arrow show",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('b-dropdown-item',{attrs:{"to":_vm.$wlink.host(row.product.host.id),"target":"_blank"}},[_vm._v(" 호스트 관리 ")]),_c('b-dropdown-item',{attrs:{"to":_vm.$wlink.productEdit(row.product.id),"target":"_blank"}},[_vm._v(" 상품 관리 ")]),_c('b-dropdown-item',{attrs:{"to":_vm.$wlink.notificationMessageByHost(row.product.host.id),"target":"_blank"}},[_vm._v(" 알림톡 발송내역 ")]),_c('b-dropdown-item',{attrs:{"href":(_vm.fripUrl + "/products/" + (row.product.id)),"disabled":!_vm.isSaleProduct(row.product.status),"target":"_blank"}},[_vm._v(" 상품 상세 바로가기 ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }