





































































































import Vue from 'vue';
import { Select, Option } from 'element-ui';
import HostSearchInput from '@/components/Host/HostSearchInput.vue';
import { InspectionService } from '../../inspection/service/InspectionService';
import {
  InspectionFilterQuery,
  InspectionTermSearchType,
  InspectionTermWithType,
} from '../../inspection/model/InspectionFilter';
import VueRouter from 'vue-router';
import { InspectionFilter } from '../model/InspectionFilter';
import { TermWithType } from '@/domain/booking/model/TicketFilter';
import { InspectionMode, InspectionState } from '../model/enum';

export default Vue.extend({
  name: 'InspectionSearchFilterForm',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    HostSearchInput,
  },
  data() {
    const query = this.$route.query as InspectionFilterQuery;

    return {
      hostId: '',
      statusIn: InspectionService.getStateLabels().map(i => i.value),
      modeIn: [InspectionMode.BEFORE, InspectionMode.AFTER],
      searchTarget: 'productTitle',
      searchKeyword: '',
      totalCount: 0,
      productSearchOptions: [
        { value: 'productTitle', text: '상품명' },
        { value: 'productId', text: '상품ID' },
        { value: 'inspection', text: '검수ID' },
      ],
      termWithType: {
        type: InspectionTermSearchType.REQUEST,
        term: {
          startedAt: null,
          endedAt: null,
        },
      } as InspectionTermWithType,
      termSearchTypeOptions: [
        { value: InspectionTermSearchType.REQUEST, text: '검수요청일' },
        { value: InspectionTermSearchType.COMPLETE, text: '검수완료일' },
      ],
      inspectionStatusLabels: InspectionService.getStateLabels(),
      inspectionModeLabels: InspectionService.getModeLabelsIn(),
      visible: false,
    };
  },
  computed: {
    searchKeywordPlaceholder(): string {
      if (this.searchTarget === 'productTitle') {
        return '검색어를 입력해주세요';
      } else if (this.searchTarget === 'productId') {
        return '상품 아이디를 , 로 구분해 입력해주세요.';
      } else {
        return '검수 아이디를 , 로 구분해 입력해주세요.';
      }
    },
    defaultStatusIn(): InspectionState[] {
      return [
        InspectionState.APPLIED,
        InspectionState.INSPECTING,
        InspectionState.PAUSED,
        InspectionState.APPROVED,
        InspectionState.CANCELED,
        InspectionState.REJECTED,
      ];
    },
    allStatusChecked: {
      get(): boolean {
        return this.statusIn?.length === this.defaultStatusIn.length;
      },
      set(newValue: boolean) {
        if (newValue) {
          this.statusIn = this.defaultStatusIn;
        } else if (this.statusIn?.length === this.defaultStatusIn.length) {
          this.statusIn = [];
        }
      },
    },
  },
  methods: {
    clear() {
      this.termWithType = {
        type: InspectionTermSearchType.REQUEST,
        term: {
          startedAt: null,
          endedAt: null,
        },
      };
      this.hostId = '';
      this.modeIn = [InspectionMode.BEFORE, InspectionMode.AFTER];
      this.statusIn = InspectionService.getStateLabels().map(i => i.value);
      this.searchKeyword = '';
      this.$router.push({ query: undefined }).catch();
    },
    buildFilter() {
      const filter: InspectionFilterQuery = {};

      if (this.searchKeyword !== '') {
        if (this.searchTarget === 'productTitle') {
          filter.titleLike = this.searchKeyword;
        } else if (this.searchTarget === 'productId') {
          filter.productIds = this.searchKeyword
            .split(',')
            .map(id => id.trim());
        } else {
          filter.inspectionIds = this.searchKeyword
            .split(',')
            .map(id => id.trim());
        }
      }
      if (
        this.termWithType?.term?.startedAt &&
        this.termWithType?.term?.endedAt
      ) {
        filter.termType = this.termWithType.type;
        filter.startedAt = this.termWithType.term.startedAt.toString();
        filter.endedAt = this.termWithType.term.endedAt.toString();
      }
      if (this.hostId !== '') {
        filter.hostId = this.hostId;
      }

      if (this.statusIn.length !== 0) {
        filter.statusIn = this.statusIn;
      }

      if (this.modeIn.length !== 0) {
        filter.modeIn = this.modeIn;
      }
      this.$router.push({ query: filter }).catch(err => {
        if (
          VueRouter.isNavigationFailure(
            err,
            VueRouter.NavigationFailureType.duplicated
          )
        ) {
          this.$emit('refresh', true);
        }
      });
    },
  },
});
