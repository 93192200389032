















































































import InlineText from '@/components/Forms/InlineText.vue';
import Instant from '@/components/Labels/Instant.vue';
import Vue from 'vue';
import {
  InspectionService,
  InspectionStateInfo,
} from '../../service/InspectionService';
import { Table, TableColumn } from 'element-ui';
import { INSPECTION_LIST_BY_PRODUCT_QUERY } from '../../graphql/query';
import InspectionModeLabel from '../../components/controls/InspectionModeLabel.vue';
import InspectionItemStatusLabel from '../../components/controls/InspectionItemStatusLabel.vue';
import InspectionStatusLabel from '../../components/controls/InspectionStatusLabel.vue';
import { InspectionState } from '../../model/enum';

const labels = InspectionService.getStateLabels();
export default Vue.extend({
  components: {
    InlineText,
    Instant,
    InspectionModeLabel,
    InspectionItemStatusLabel,
    InspectionStatusLabel,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    product: Object,
  },
  data() {
    return {
      labels,
      inspections: [],
      productId: this.$props.product.id || '',
    };
  },
  watch: {
    '$props.product': {
      immediate: true,
      handler(newVal) {
        if (newVal.id) {
          this.productId = newVal.id;
        }
      },
    },
  },
  methods: {
    stateLabel(state: InspectionState): InspectionStateInfo | undefined {
      const label = labels.find(label => label.value === state);
      return label;
    },
  },
  apollo: {
    inspections: {
      query: INSPECTION_LIST_BY_PRODUCT_QUERY,
      variables(): { productId: string } {
        return {
          productId: this.product.id,
        };
      },
      update(data) {
        return data.product.inspectionsByProduct;
      },
      skip(): boolean {
        return this.productId.length === 0;
      },
    },
  },
});
