export enum InspectionState {
  APPLIED = 'APPLIED',
  INSPECTING = 'INSPECTING',
  PAUSED = 'PAUSED',
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
}

export enum InspectionItemState {
  BLOCKED = 'BLOCKED',
  READY = 'READY',
  PAUSED = 'PAUSED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
}

export enum InspectionMode {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
}

export enum InspectionPart {
  MD = 'MD',
}

export enum InspectionFlowVersion {
  V1 = 'V1',
}
