





















































































import Vue from 'vue';
import Instant from '@/components/Labels/Instant.vue';
import InlineText from '@/components/Forms/InlineText.vue';
import { Table, TableColumn } from 'element-ui';
import InputRow from '@/components/Forms/InputRow.vue';
import { InventoryTargetType } from '@/domain/product/model/product/enum';

export default Vue.extend({
  components: {
    Instant,
    InlineText,
    InputRow,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    product: {
      type: Object,
    },
  },
  computed: {
    isFrip(): boolean {
      return this.product.kind !== 'GOODS';
    },
    inventoryTargetType(): string {
      if (this.product.inventoryTargetType === InventoryTargetType.BY_ITEM) {
        return '옵션별 모집인원 제한';
      } else if (
        this.product.inventoryTargetType === InventoryTargetType.BY_OPTION
      ) {
        return '옵션별 모집인원 제한';
      } else if (
        this.product.inventoryTargetType === InventoryTargetType.BY_SCHEDULE
      ) {
        return '일정별 모집인원 제한';
      }

      return '';
    },
  },
});
