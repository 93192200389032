








import Vue from 'vue';
import { DISPLAYS } from './queries/query';
import { ApolloError } from 'apollo-client';
import { DisplayCategory } from '@/domain/category/display/model/DisplayCategory';

interface DisplayCategoryFilter {
  ids: string[];
}
interface ProductCategory {
  displayCategory: DisplayCategory;
  representative: boolean;
}
export default Vue.extend({
  name: 'DisplayCategoryPathContainer',
  props: {
    productCategories: {
      type: Array,
      required: true,
    },
  },
  data(): {
    page: number;
    size: number;
    filter: DisplayCategoryFilter;
    pathList: { path: string; representative: boolean }[];
  } {
    return {
      filter: { ids: [] },
      page: 1,
      size: 10,
      pathList: [],
    };
  },
  computed: {
    categories(): ProductCategory[] {
      return this.productCategories as ProductCategory[];
    },
  },
  apollo: {
    pathList: {
      query: DISPLAYS,
      variables(): {
        page: number;
        size: number;
        filter: DisplayCategoryFilter;
      } {
        return {
          filter: {
            ids: this.categories.map(productCategory =>
              String(productCategory.displayCategory.id)
            ),
          },
          page: this.page,
          size: this.size,
        };
      },
      update(data): { path: string; representative: boolean }[] {
        const displayCategories = data.category.displays.edges.map(
          (edge: { node: DisplayCategory }) => edge.node
        );
        const pathList = [];
        for (let display of displayCategories) {
          let path: string[] = [];
          let representative: boolean =
            this.categories.find(
              category => category.displayCategory.id === display.id
            )?.representative || false;
          while (display) {
            path.unshift(display.label.name);
            console.log(path);
            display = display.parent;
            console.log(display);
          }
          pathList.push({ path: path.join(' > '), representative });
        }
        console.log(pathList);
        return pathList;
      },
      error(error: ApolloError) {
        console.log(error);
      },
    },
  },
});
