import { Dictionary } from 'vue-router/types/router';
import { Term } from '../../../common/Term';
import { InspectionState } from './enum';

export interface InspectionFilter {
  productIds: string[] | null;
  inspectionIds: string[] | null;
  titleLike: string | null;
  hostId: string | null;
  statusIn: InspectionState[] | null;
  termWithType: InspectionTermWithType | null;
  modeIn: InspectionMode[] | null;
}

export interface InspectionTermWithType {
  type: InspectionTermSearchType;
  term: Term;
}

export enum InspectionTermSearchType {
  REQUEST = 'REQUEST',
  COMPLETE = 'COMPLETE',
}

export enum InspectionMode {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
}

export interface InspectionFilterQuery
  extends Dictionary<string | string[] | undefined> {
  productIds?: string[];
  inspectionIds?: string[];
  titleLike?: string;
  hostId?: string;
  statusIn?: string[];
  termType?: string;
  startedAt?: string;
  endedAt?: string;
  modeIn?: string[];
}
