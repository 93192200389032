import {
  InspectionState,
  InspectionMode,
  InspectionPart,
  InspectionItemState,
  InspectionFlowVersion,
} from './enum';
import { CommonState } from '@frientrip/domain';

export interface Inspection {
  completedAt?: Date;
  createdAt?: Date;
  flowVersion: InspectionFlowVersion;
  host: {
    id: string;
  };
  id: string;
  items: [InspectionItem];
  mode: InspectionMode;
  product: {
    id: string;
  };
  status: InspectionState;
}

export interface ReasonForInspectionRejection {
  id: string;
  name: string;
  description: string;
  requiredComment: boolean;
  status: CommonState;
  part: InspectionPart;
}

export interface InspectionItem {
  comment: string;
  id: string;
  part: InspectionPart;
  reasons: ReasonForInspectionRejection[];
  status: InspectionItemState;
  worker: {
    id: string;
    nickname: string;
  };
}

export interface History {
  id: string;
  items: HistoryItem[];
  worker: {
    id: string;
    nickname: string;
  };
}

interface Content {
  id: string;
  uri: string;
  __typename: 'Content';
}

export type HistorySubjectUnion = Content;

export interface HistoryItem {
  id: string;
  changeType: SubjectChangeType;
  element?: {
    elementName: string;
    elementType: string;
    value: string;
    beforeValue: string;
  };
  subjectName: string;
  subjectValue: string;
  subject: HistorySubjectUnion;
  subjectId: string;
}

export enum SubjectChangeType {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  UPDATE = 'UPDATE',
}
