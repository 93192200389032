




































































































































import Vue from 'vue';
import { isHostAdmin } from '@/env';
import {
  HISOTRIES_AFTER_LAST_APPROVED,
  PRODUCT_VIEW_QUERY,
  SCHEDULES_BY_TERM,
} from '../graphql/query';
import { InspectionState } from '../model/enum';
import InspectionPopup from '../components/InspectionPopup.vue';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Pagination,
  Table,
  TableColumn,
} from 'element-ui';
import ContentCard from '@/components/FripComponents/ContentCard.vue';
import ProductBaseInfoForm from './form/ProductBaseInfoForm.vue';
import ProductSaleInfoForm from './form/ProductSaleInfoForm.vue';
import ProductDescriptionForm from './form/ProductDescriptionForm.vue';
import ProductAdditionalInfoForm from './form/ProductAdditionalInfoForm.vue';
import ProductOtherInfoForm from './form/ProductOtherInfoForm.vue';
import ProductTransactionInfoForm from './form/ProductTransactionInfoForm.vue';
import ProductItemListForm from './form/ProductItemListForm.vue';
import InspectionHistory from './form/InspectionHistory.vue';
import ScheduleCalenderForm from '../components/schedule/ScheduleCalenderForm.vue';
import {
  CurriculumKind,
  CurriculumStyle,
  InventoryTargetType,
  LevelOfDifficulty,
  ProductKind,
} from '../../product/model/product/enum';
import { ProductConvertService } from '@/domain/product/service/ProductConvertService';
import InspectionInfoForm from './form/InspectionInfoForm.vue';

const productConvertService = new ProductConvertService();

export default Vue.extend({
  name: 'InspectionViewContainer',
  components: {
    ProductDescriptionForm,
    InspectionPopup,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    ContentCard,
    InspectionInfoForm,
    ProductBaseInfoForm,
    ProductSaleInfoForm,
    ProductAdditionalInfoForm,
    ProductOtherInfoForm,
    ProductTransactionInfoForm,
    ProductItemListForm,
    ScheduleCalenderForm,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    InspectionHistory,
  },
  data() {
    return {
      historiesAfterLastApproved: [],
      productId: this.$route.params.id || '',
      isAdmin: !isHostAdmin(),
      loading: 0,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      isFrip: true,
      schedules: [],
      product: {
        host: {
          id: '',
          name: '',
        },
        id: '',
        title: '',
        catchphrase: '',
        cancelingRestricted: false,
        categories: [],
        attributes: [],
        exposures: [],
        headerContents: [],
        contents: [{ content: {} }],
        htmlDetailContent: '',
        items: [],
        keywords: [],
        kind: ProductKind.NONE,
        managerId: null,
        notice: `[신청 시 유의사항]
      - 구매시 호스트 연락처를 카톡 혹은 문자로 보내드립니다.
      - 호스트 연락처로 진행 가능한 날짜 예약 바랍니다.
      - 예약 확정 시 호스트가 출석체크를 진행합니다.
      - 예약 시간에 맞추어 늦지 않게 도착해주시기 바랍니다.`,
        inquiryTemplateId: '',
        options: [
          {
            title: '',
            names: [],
          },
        ],
        productContentIds: [],
        saleTerm: {
          endedAt: 0,
          startedAt: new Date().valueOf(),
        },
        maximumPurchasableCount: 0, // 최대 주문 수량(0이면 제한 없음)
        standardCategoryId: '',
        externalProvisionIds: [],
        tagIds: [],
        underageRestricted: false,
        inventoryTargetOptionLevel: 0, // 옵션별 모집인원 제한일때 사용할 예정(현재 사용안함 default: 0)
        inventoryTargetType: InventoryTargetType.BY_ITEM,
        refundPolicy: {
          name: '',
        },
        frip: {
          attachedToSchedule: false,
          bookingConfirmationEnabled: false,
          curriculum: {
            kind: CurriculumKind.SCHEDULE,
            sections: [],
            style: CurriculumStyle.NONE,
          },
          daysOfExpiration: 90,
          difficulty: LevelOfDifficulty.NONE,
          exclusions: [],
          inclusions: [],
          locationOfGathering: null,
          locationsOfVenue: [],
          plans: [],
          recommendedAge: 0,
          schedules: [],
          stuffsToPrepare: [],
        },
        grossPurchaseLimitation: {
          count: 0,
        },
        lastInspection: {
          id: 0,
          status: InspectionState.INSPECTING,
          items: [{ part: '' }],
        },
      },
    };
  },
  computed: {
    inInspecting(): boolean {
      return (
        this.product.lastInspection?.id > 0 &&
        (this.product.lastInspection?.status == InspectionState.APPLIED ||
          this.product.lastInspection?.status == InspectionState.INSPECTING)
      );
    },
    scheduleParams(): any {
      return productConvertService.convertSchedules(this.schedules);
    },
  },
  watch: {
    '$route.params': {
      immediate: true,
      handler(newVal) {
        if (newVal.id) {
          this.productId = newVal.id;
        }
      },
    },
  },
  methods: {
    openProductPage() {
      const productUrl = this.$wlink.productEdit(this.product.id);
      window.open(productUrl);
    },
    onClickInspection(): void {
      const popup = this.$refs.InspectionPopup as InstanceType<
        typeof InspectionPopup
      >;
      popup?.open(
        String(this.product.lastInspection?.id),
        this.product.lastInspection?.items[0].part
      );
    },
  },
  apollo: {
    product: {
      query: PRODUCT_VIEW_QUERY,
      variables(): { productId: string } {
        return {
          productId: this.productId,
        };
      },
      update(data) {
        this.isFrip = this.product.kind !== 'GOODS';
        console.log(data);
        return data.product.product;
      },
    },
    historiesAfterLastApproved: {
      query: HISOTRIES_AFTER_LAST_APPROVED,
      variables(): { productId: string } {
        return {
          productId: this.productId,
        };
      },
      update(data) {
        this.isFrip = this.product.kind !== 'GOODS';
        console.log(data);
        return data.product.historiesAfterLastApproved;
      },
    },

    schedules: {
      query: SCHEDULES_BY_TERM,
      variables(): {
        productId: string;
        schedulingTerm: { endedAt: number; startedAt: number };
      } {
        const startedAt = new Date();
        const endDay = new Date();
        endDay.setMonth(endDay.getMonth() + 3);

        return {
          productId: this.productId,
          schedulingTerm: {
            endedAt: endDay.getTime(),
            startedAt: startedAt.getDate(),
          },
        };
      },
      update: data => data.product.schedulesByTerm,
    },
  },
});
