
















































import InlineText from '@/components/Forms/InlineText.vue';
import Vue from 'vue';
import { CurriculumStyle } from '@/domain/product/model/product/enum';
import CurriculumView from '@/domain/inspection/components/curriculum/CurriculumView.vue';

export default Vue.extend({
  components: { InlineText, CurriculumView },
  props: {
    product: Object,
    isFrip: Boolean,
  },
  // data() {
  //   return {
  //     product: this.$props.product,
  //   };
  // },
  computed: {
    curriculumStyle(): string {
      if (this.product.frip.curriculum.style === CurriculumStyle.NONE) {
        return '없음';
      } else if (
        this.product.frip.curriculum.style === CurriculumStyle.DURATION
      ) {
        return '시간표로 작성';
      } else if (
        this.product.frip.curriculum.style === CurriculumStyle.TIMETABLE
      ) {
        return '구간표로 작성';
      } else if (
        this.product.frip.curriculum.style === CurriculumStyle.LEGACY
      ) {
        return '기타';
      }

      return '';
    },
  },
});
