
















import Vue from 'vue';
export default Vue.extend({
  name: 'StackTable',
  props: {
    data: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
  },
});
