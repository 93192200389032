var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    ("bg-frip-" + _vm.themeColor),
    ("text-frip-" + _vm.textColor),
    'rounded',
    'p-1',
    'my-2',
    'wg-100',
    _vm.border ],staticStyle:{"cursor":"pointer"},on:{"click":_vm.updateSchedule}},[_c('div',{staticClass:"clearfix"},[_c('div',{staticClass:"float-left"},[_vm._v(_vm._s(_vm.start))]),_c('div',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.statusLabel)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }