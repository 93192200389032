









import Vue from 'vue';
import InspectionSearchFilterForm from '../components/InspectionSearchFilterForm.vue';
import InspectionSearchList from '../components/InspectionSearchList.vue';
import { InspectionFilter } from '../model/InspectionFilter';

export default Vue.extend({
  components: {
    InspectionSearchList,
    InspectionSearchFilterForm,
  },
  data() {
    return {
      filter: null as InspectionFilter | null,
    };
  },
  methods: {
    onRefresh() {
      (this.$refs.list as any).refresh();
    },
  },
});
