




import Vue from 'vue';
import { InspectionMode } from '../../model/enum';

const inspectionModes = [
  {
    value: InspectionMode.BEFORE,
    label: '신규',
    variation: 'default',
  },
  {
    value: InspectionMode.AFTER,
    label: '수정',
    variation: 'primary',
  },
];

export default Vue.extend({
  name: 'InspectionModeLabel',
  props: {
    mode: {
      type: String,
    },
  },
  computed: {
    variation: function () {
      return inspectionModes.find(i => i.value.toString() == this.mode)
        ?.variation;
    },
    label: function () {
      return inspectionModes.find(i => i.value.toString() == this.mode)?.label;
    },
  },
});
