var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-card',{attrs:{"title":"변경 사항"}},[_c('b-card-body',[_c('div',{style:({
          overflow: 'auto',
          maxHeight: '1000px',
          overflowX: 'auto',
        })},[_c('table',{staticClass:"table border"},[_c('tr',[_c('td'),_c('th',{attrs:{"scope":"col","width":"4%"}},[_vm._v("구분")]),_c('th',{attrs:{"scope":"col","width":"40%"}},[_vm._v("수정 전")]),_c('th',{attrs:{"scope":"col","width":"40%"}},[_vm._v("수정 후")])]),_vm._l((_vm.historyItems.filter(
              function (item) { return !_vm.isProductContent(item); }
            )),function(item){return _c('tr',{key:item.id},[_c('td',{attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(_vm.convertHistoryItemToLabel(item, _vm.options))+" ")]),_c('td',[_vm._v(_vm._s(_vm.getChangeTypeLabel(item)))]),(_vm.isProductContent(item))?_c('td',{style:({
                justifyContent: 'center',
              })}):(_vm.isHeaderContent(item))?_c('td',[(item.changeType === 'REMOVE')?_c('img',{attrs:{"src":_vm.getBeforeValueByChangeType(item),"width":"400px"}}):_vm._e()]):(_vm.isDisplayCategory(item))?_c('td',[(item.changeType === 'REMOVE')?_c('display-category-path-container',{attrs:{"productCategories":[
                  { displayCategory: { id: item.subjectId } } ]}}):_vm._e()],1):(_vm.isStandardCategory(item))?_c('td',[(item.changeType === 'REMOVE')?_c('standard-category-path',{attrs:{"standardCategoryId":item.subjectId}}):_vm._e()],1):_c('td',[_vm._v(_vm._s(_vm.getBeforeValueByChangeType(item)))]),(_vm.isProductContent(item))?_c('td',{style:({
                justifyContent: 'center',
              })}):(_vm.isHeaderContent(item))?_c('td',[(item.changeType === 'ADD')?_c('img',{attrs:{"src":_vm.getValueByChangeType(item),"width":"400px"}}):_vm._e()]):(_vm.isDisplayCategory(item))?_c('td',[(item.changeType === 'ADD')?_c('display-category-path-container',{attrs:{"productCategories":[
                  { displayCategory: { id: item.subjectId } } ]}}):_vm._e()],1):(_vm.isStandardCategory(item))?_c('td',[(item.changeType === 'ADD')?_c('standard-category-path',{attrs:{"standardCategoryId":item.subjectId}}):_vm._e()],1):_c('td',[_vm._v(_vm._s(_vm.getValueByChangeType(item)))])])})],2)]),(_vm.historyItems.filter(function (item) { return _vm.isProductContent(item); }).length > 0)?_c('div',[_c('h1',{staticClass:"text-center my-5"},[_vm._v("상품 상세 변경 요약")]),_vm._l((_vm.historyItems.filter(function (item) { return _vm.isProductContent(item); })),function(item){return _c('div',{key:item.id},[_c('b-row',{staticClass:"text-center"},[_c('b-col',{staticClass:"text-center justify-content-md-center",attrs:{"lg":"6"}},[_c('h2',[_vm._v("Before")]),_c('div',{staticClass:"img-max-width",domProps:{"innerHTML":_vm._s(_vm.getBeforeValueByChangeType(item))}})]),_c('b-col',{staticClass:"text-center justify-content-md-center",attrs:{"lg":"6"}},[_c('h2',[_vm._v("After")]),_c('div',{staticClass:"img-max-width text-center justify-content-md-center",domProps:{"innerHTML":_vm._s(_vm.getValueByChangeType(item))}})])],1),_c('b-row',{staticClass:"justify-content-md-center text-center my-5"},[_c('b-col',{attrs:{"lg":"6"}},[_c('h2',[_vm._v("Summary")]),_c('div',{domProps:{"innerHTML":_vm._s(
                  _vm.getHtmlDiff(
                    _vm.getBeforeValueByChangeType(item),
                    _vm.getValueByChangeType(item),
                    'htmlDiffClass'
                  )
                )}})])],1)],1)})],2):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }