import { Term } from '@/common/Term';

export class ConvertService {
  protected convertTerm(term: Term): Term | null {
    const convertedTerm: Term = {};

    if (term === {}) {
      return null;
    } else if (!term.startedAt && !term.endedAt) {
      return null;
    }

    if (term.startedAt && term.startedAt !== 0) {
      convertedTerm.startedAt = term.startedAt;
    }
    if (term.endedAt && term.endedAt !== 0) {
      convertedTerm.endedAt = term.endedAt;
    }

    if (term.startedAt && term.endedAt && term.startedAt > term.endedAt) {
      throw new Error('시작일은 종료일보다 빨라야 합니다.');
    }

    return convertedTerm;
  }
}
