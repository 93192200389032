



























import Vue from 'vue';
import { ProductHeaderContentStyle } from '@/domain/product/model/product/enum';
import {
  HeaderContent,
  ProductHeaderContent,
} from '@/domain/product/model/product/response/product';

interface Image {
  contentId: string;
  url: string;
}

export default Vue.extend({
  name: 'ProductDescriptionForm',
  components: {},
  props: {
    product: {
      type: Object,
    },
    isUpdateForm: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: this.product,
      style: ProductHeaderContentStyle.SQUARE,
      descriptions: [
        '권장 사이즈: 가로 1000px * 세로 1000px',
        '최소 사이즈: 가로 600px * 세로 600px',
        '용량: 10MB 이하, 파일유형: JPG, PNG 최소 1장 - 최대 5장 까지 등록가능',
      ],
      contentIds: [] as string[],
    };
  },
  computed: {
    images(): Image[] {
      return this.product.headerContents.map(
        (header: ProductHeaderContent) => ({
          contentId: header.content?.id,
          url: header.content?.uri,
        })
      );
    },
    htmlDetailContent(): string {
      return this.product.contents[0].content;
    },
  },
  methods: {
    openOriginalImage(image: Image) {
      window.open(image.url);
    },
  },
});
