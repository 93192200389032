




import Vue from 'vue';
import { InspectionItemState } from '../../model/enum';

const inspectionItemStates = [
  {
    value: InspectionItemState.APPROVED,
    label: '검수승인',
    variation: 'primary',
  },
  {
    value: InspectionItemState.READY,
    label: '검주준비',
    variation: 'success',
  },
  {
    value: InspectionItemState.REJECTED,
    label: '검수반려',
    variation: 'danger',
  },
  {
    value: InspectionItemState.DELETED,
    label: '삭제',
    variation: 'danger',
  },
  {
    value: InspectionItemState.PAUSED,
    label: '검수중지',
    variation: 'danger',
  },
  {
    value: InspectionItemState.BLOCKED,
    label: '진행불가',
    variation: 'danger',
  },
];

export default Vue.extend({
  name: 'inspectionItemStatesLabel',
  props: {
    status: {
      type: String,
    },
  },
  computed: {
    variation: function () {
      return inspectionItemStates.find(i => i.value.toString() == this.status)
        ?.variation;
    },
    label: function () {
      return inspectionItemStates.find(i => i.value.toString() == this.status)
        ?.label;
    },
  },
});
