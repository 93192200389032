import gql from 'graphql-tag';

export const INSPECTION_LIST_QUERY = gql`
  query getInspectionsByFilter(
    $filter: InspectionFilterInput
    $page: Int
    $size: Int
  ) {
    product {
      inspections(filter: $filter, page: $page, size: $size) {
        totalCount
        edges {
          node {
            id
            product {
              id
              status
              title
              host {
                id
                name
              }
            }
            items {
              id
              worker {
                id
                nickname
              }
              status
            }
            mode
            status
            createdAt
            completedAt
          }
        }
      }
    }
  }
`;

export const INSPECTION_LIST_BY_PRODUCT_QUERY = gql`
  query inspectionsByProduct($productId: ID!) {
    product {
      inspectionsByProduct(productId: $productId) {
        id
        product {
          id
          title
          host {
            id
            name
          }
        }
        items {
          id
          worker {
            id
            nickname
          }
          comment
          part
          reasons {
            id
            description
            part
            name
            requiredComment
            status
          }
          status
        }
        mode
        status
        createdAt
        completedAt
      }
    }
  }
`;

export const PRODUCT_VIEW_QUERY = gql`
  query GetProduct($productId: ID!) {
    product {
      product(id: $productId) {
        id
        title
        refundPolicy {
          id
          name
          extraNotice
          description
          representative
          status
          type
        }
        host {
          id
          name
          super
          commission {
            absent {
              bias
              charge
              type
            }
            default {
              bias
              charge
              type
            }
          }
        }
        catchphrase
        manager {
          id
          name
          departmentName
          status
          staffNo
        }
        staff {
          id
          name
          departmentName
          status
          staffNo
        }
        headerContents {
          id
          style
          content {
            id
            type
            uri
            thumbnail(width: 500, height: 500)
          }
        }
        categories {
          displayCategory {
            id
            label {
              id
              name
            }
            status
          }
          representative
        }
        standardCategories {
          id
          label {
            id
            name
          }
          status
        }
        kind
        attributes {
          id
          name
          kind
          description
          status
        }
        exposures {
          id
          name
          status
        }
        saleTerm {
          startedAt
          endedAt
        }
        representativePrice {
          sale
          retail
          currencyCode
          discountRate
        }
        contents {
          id
          type
          style
          content
          updatedAt
          status
        }
        inquiryTemplate {
          id
          inquiries {
            id
            title
          }
        }
        items {
          id
          type
          name
          description
          options {
            id
            title
            level
            name
            status
          }
          representative
          quota
          minimumQuota
          maximumPurchasableCount
          price {
            sale
            retail
            currencyCode
            discountRate
          }
          salesType
          commission {
            type
            charge
            bias
          }
          absentFee {
            type
            charge
            bias
          }
          saleTerm {
            startedAt
            endedAt
          }
          scheduledBySale
          status
          indexNo
          ticketCount
          daysOfExpiration
          additionalInquiries {
            id
            title
            inputKind
            inputPattern
            items {
              id
              label
              value
              status
            }
            required
            status
          }
          canSale
        }
        options {
          id
          title
          names
          level
          status
        }
        tags {
          id
          kind
          name
          textColor
          backgroundColor
          status
        }
        keywords
        underageRestricted
        cancelingRestricted
        notice
        inventoryTargetType
        inventoryTargetOptionLevel
        frip {
          plans {
            id
            appliedAllItems
            name
            minimumQuota
            preparatoryPeriod
            quota
            overNight
            term {
              startedAt
              endedAt
            }
            targetItems {
              id
            }
            times {
              starting {
                hour
                minute
              }
              duration
            }
            weeks
          }
          locationOfGathering {
            id
            kind
            location {
              id
              parking {
                available
                note
              }
              conveniences {
                id
                name
              }
            }
            label
            address
            overseas
            geoPoint {
              latitude
              longitude
            }
            status
          }
          locationsOfVenue {
            id
            kind
            location {
              id
              parking {
                available
                note
              }
              conveniences {
                id
                name
              }
            }
            label
            address
            overseas
            geoPoint {
              latitude
              longitude
            }
            status
          }
          difficulty
          attachedToSchedule
          daysOfExpiration
          curriculum {
            title
            kind
            style
            sections {
              id
              title
              items {
                id
                textTime
                duration
                term {
                  startingTime {
                    hour
                    minute
                  }
                  endingTime {
                    hour
                    minute
                  }
                }
                content
                status
              }
              etc
              status
            }
          }
          inclusions
          exclusions
          stuffsToPrepare
          recommendedAge
          bookingConfirmationEnabled
        }
        overseas
        externalProvisions {
          id
          name
          status
        }
        status
        canSale
        firstOpenedAt
        period {
          startedAt
          endedAt
        }
        createdAt
        updatedAt
        grossPurchaseLimitation {
          count
        }
        lastInspection {
          id
          mode
          items {
            id
            part
            comment
            reasons {
              id
              name
              description
            }
            worker {
              id
              nickname
            }
            status
          }
          createdAt
          completedAt
          status
        }
      }
    }
  }
`;

export const REASONS_OF_REJECTION = gql`
  query GetReasons {
    product {
      reasonsForRejection {
        id
        name
        description
        requiredComment
        status
      }
    }
  }
`;

export const SCHEDULES_BY_TERM = gql`
  query schedulesByTerm($productId: ID!, $schedulingTerm: FixedTermInput!) {
    product {
      schedulesByTerm(productId: $productId, schedulingTerm: $schedulingTerm) {
        id
        appliedAllItems
        items {
          id
          name
        }
        plan {
          id
        }
        term {
          startedAt
          endedAt
          duration
        }
        saleTerm {
          startedAt
          endedAt
        }
        minimumQuota
        quota
        maximumPurchasableCount
        title
        status
      }
    }
  }
`;

export const HISOTRIES_AFTER_LAST_APPROVED = gql`
  query historiesAfterLastApproved($productId: ID!) {
    product {
      historiesAfterLastApproved(productId: $productId) {
        id
        items {
          id
          changeType
          element {
            elementName
            elementType
            value
            beforeValue
          }
          subjectName
          subjectId
          subjectValue
          subject {
            __typename
            ... on Content {
              uri
            }
          }
        }
      }
    }
  }
`;
