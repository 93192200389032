














import Vue from 'vue';
import { TagKind } from '@/domain/product/model/product/enum';

export default Vue.extend({
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {
      form: this.product,
    };
  },
  computed: {
    externalProvisionIds() {
      return (
        this.product?.externalProvisions?.map(
          (provision: { id: string }) => provision.id
        ) || []
      );
    },
  },
  methods: {
    tags(kind: TagKind) {
      return (
        this.product?.tags
          ?.filter((tag: { kind: TagKind; name: string }) => tag.kind === kind)
          ?.map((tag: { kind: TagKind; name: string }) => tag.name)
          ?.join(',') || '없음'
      );
    },
  },
});
