






import Vue from 'vue';
import { STANDARD_CATEGORY } from './queries/query';
import { ApolloError } from 'apollo-client';

export default Vue.extend({
  name: 'StandardCategoryPath',
  props: {
    standardCategoryId: {
      type: String,
      required: true,
    },
  },
  data(): { path: string } {
    return {
      path: '',
    };
  },
  apollo: {
    path: {
      query: STANDARD_CATEGORY,
      variables(): { id: string } {
        return {
          id: this.standardCategoryId,
        };
      },
      skip(): boolean {
        return this.standardCategoryId === '';
      },
      update: data => {
        let category = data.category.standard;
        let path: string[] = [];
        while (category) {
          path.unshift(category.label.name);
          category = category.parent;
        }
        path.shift();
        return path.join(' > ');
      },
      error(error: ApolloError) {
        console.log(error);
      },
    },
  },
  watch: {
    standardCategoryId(newValue: string) {
      if (newValue === '') {
        console.log(newValue);
        this.path = '';
      }
    },
  },
});
