



























































































import Vue from 'vue';
import StackTable from '@/components/Table/StackTable.vue';
import {
  ProductKind,
  LevelOfDifficulty,
  FripKind,
} from '@/domain/product/model/product/enum';
import StandardCategoryPath from '@/components/Category/StandardCategoryPath.vue';
import DisplayCategoryPathContainer from '@/components/Category/DisplayCategoryPathContainer.vue';
import InlineText from '@/components/Forms/InlineText.vue';
import { HistoryItem, SubjectChangeType } from '../../model/Inspection';

export default Vue.extend({
  components: {
    StackTable,
    StandardCategoryPath,
    DisplayCategoryPathContainer,
    InlineText,
  },
  props: {
    product: {
      type: Object,
    },
  },
  // data() {
  //   return {
  //     history: this.histories[0] || { items: [] },
  //   };
  // },
  computed: {
    productDifficulty(): string {
      if (this.product.frip.difficulty === LevelOfDifficulty.NONE) {
        return '없음';
      } else if (this.product.frip.difficulty === LevelOfDifficulty.VERY_EASY) {
        return '매우 쉬움';
      } else if (this.product.frip.difficulty === LevelOfDifficulty.EASY) {
        return '쉬움';
      } else if (this.product.frip.difficulty === LevelOfDifficulty.NORMAL) {
        return '보통';
      } else if (this.product.frip.difficulty === LevelOfDifficulty.HIGH) {
        return '어려움';
      }

      return '';
    },
    productKind(): string {
      if (this.product.kind === ProductKind.NONE) {
        return '없음';
      } else if (this.product.kind === ProductKind.GOODS) {
        return '배송 상품';
      } else if (this.product.kind === ProductKind.OFFLINE) {
        return '오프라인';
      } else if (this.product.kind === ProductKind.ONLINE) {
        return '온라인';
      } else if (this.product.kind === ProductKind.EVENT) {
        return '이벤트';
      }
      return '';
    },
    fripKind(): string {
      if (this.product.frip.kind === FripKind.COMMON) {
        return '일반 프립';
      } else if (this.product.kind === FripKind.MEETUP) {
        return '번개 프립';
      }
      return '';
    },
    locationsOfVenue(): any {
      const locationsOfVenue = this.product?.frip?.locationsOfVenue;
      if (!locationsOfVenue) {
        return;
      }
      return this.product?.frip?.locationsOfVenue.map((location: any) => ({
        ...location,
        geoInfo: `위도 : ${location.geoPoint.latitude}, 경도 : ${location.geoPoint.longitude}`,
      }));
    },
    locationOfGathering(): any {
      const locationOfGathering = this.product?.frip?.locationOfGathering;
      if (!locationOfGathering) {
        return;
      }
      return {
        ...locationOfGathering,
        geoInfo: `위도 : ${locationOfGathering?.geoPoint?.latitude}, 경도 : ${locationOfGathering?.geoPoint?.longitude}`,
      };
    },
    isFrip(): boolean {
      return this.product.kind !== ProductKind.GOODS;
    },
    isOfflineFrip(): boolean {
      return this.product.kind === ProductKind.OFFLINE;
    },
  },
  // methods: {
  //   showHistory(
  //     subjectName: string,
  //     elementName: string,
  //     historyItems: HistoryItem[]
  //   ): string | undefined {
  //     console.log(subjectName, elementName, historyItems);
  //     const historyItem = historyItems.find(item => {
  //       if (item.changeType === SubjectChangeType.UPDATE) {
  //         console.log(
  //           item.subjectName.localeCompare(subjectName, undefined, {
  //             sensitivity: 'accent',
  //           }),
  //           item.element.elementName.localeCompare(elementName, undefined, {
  //             sensitivity: 'accent',
  //           })
  //         );
  //         return (
  //           !item.subjectName.localeCompare(subjectName, undefined, {
  //             sensitivity: 'accent',
  //           }) &&
  //           !item.element.elementName.localeCompare(elementName, undefined, {
  //             sensitivity: 'accent',
  //           })
  //         );
  //       }
  //     });
  //     console.log(historyItem);
  //     if (historyItem?.changeType === SubjectChangeType.UPDATE) {
  //       return historyItem?.element.beforeValue;
  //     } else if (historyItem?.changeType === SubjectChangeType.ADD) {
  //       return '추가됨';
  //     } else {
  //       return '삭제됨';
  //     }
  //   },
  // },
});
