



































import Vue from 'vue';
import { Select, Option } from 'element-ui';
import InlineText from '@/components/Forms/InlineText.vue';

export default Vue.extend({
  name: 'ProductTransactionInfoForm',
  components: {
    InlineText,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {
      form: this.product,
    };
  },
});
