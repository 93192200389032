
















import Vue from 'vue';
export default Vue.extend({
  name: 'ScheduleCardTypeDescription',
  data() {
    return {
      scheduleTypes: [
        {
          name: '반복없음',
          type: 'primary',
        },
        {
          name: '매일반복',
          type: 'primary-300',
        },
        {
          name: '매주반복',
          type: 'warning',
        },
      ],
      typeStyle: {
        display: 'inline-block',
        width: '1rem',
        height: '1rem',
        borderRadius: '0.2rem',
      },
    };
  },
});
