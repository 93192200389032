var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{attrs:{"data":_vm.product.items}},[_c('el-table-column',{attrs:{"label":"옵션명","prop":"name"}}),_c('el-table-column',{attrs:{"label":"정가"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(Number(row.price.retail).toLocaleString())+" "+_vm._s(row.price.currencyCode === 'KRW' ? ' 원' : ' 달러')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"판매가"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(Number(row.price.sale).toLocaleString())+" "+_vm._s(row.price.currencyCode === 'KRW' ? ' 원' : ' 달러')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"기간 한정 판매"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.saleTerm)?_c('div',[_c('instant',{attrs:{"at":row.saleTerm.startedAt}}),_vm._v(" ~ "),_c('instant',{attrs:{"at":row.saleTerm.endedAt}})],1):_c('div',[_vm._v("없음")])]}}])}),_c('el-table-column',{attrs:{"label":"모집인원제한"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.quota)?_c('div',[_vm._v(" "+_vm._s(row.minimumQuota + ' 명' + ' ~ ' + row.quota + ' 명')+" ")]):_c('div',[_vm._v("제한없음")])]}}])}),_c('el-table-column',{attrs:{"label":"1인당 최대 구매 수량"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.maximumPurchasableCount ? row.maximumPurchasableCount + ' 개' : '없음')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"수수료"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.commission.type === 'RATIO' ? ((Number(row.commission.bias).toLocaleString()) + " 원 제외 " + (row.commission.charge) + "%") : ("1개당 " + (row.commission.charge) + "원"))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"노쇼 수수료"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.absentFee.type === 'RATIO' ? ((Number(row.absentFee.bias).toLocaleString()) + " 원 제외 " + (row.absentFee.charge) + "%") : ("1개당 " + (row.absentFee.charge) + "원"))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }