import gql from 'graphql-tag';

export const INSPECTION_APPROVE_MUTATION = gql`
  mutation ApproveInspection(
    $inspectionId: ID!
    $param: InspectionParamInput!
  ) {
    approveInspection(inspectionId: $inspectionId, param: $param) {
      id
      status
    }
  }
`;

export const INSPECTION_REJECT_MUTATION = gql`
  mutation RejectInspection($inspectionId: ID!, $param: InspectionParamInput!) {
    rejectInspection(inspectionId: $inspectionId, param: $param) {
      id
      status
    }
  }
`;
