import gql from 'graphql-tag';

export const STANDARD_LEAVES = gql`
  query standardLeaves($page: Int, $size: Int, $filter: LeafCategoryFilter) {
    category {
      standardLeaves(page: $page, size: $size, filter: $filter) {
        edges {
          node {
            id
            parent {
              id
              label {
                id
                name
              }
              status
              parent {
                id
                label {
                  id
                  name
                }
                status
                parent {
                  id
                  label {
                    id
                    name
                  }
                  status
                  parent {
                    id
                    label {
                      id
                      name
                    }
                    status
                  }
                }
              }
            }
            label {
              id
              name
            }
            status
          }
        }
        totalCount
      }
    }
  }
`;

export const STANDARD_CATEGORY = gql`
  query standardCategory($id: ID!) {
    category {
      standard(id: $id) {
        id
        parent {
          id
          label {
            id
            name
          }
          parent {
            id
            label {
              id
              name
            }
            parent {
              id
              label {
                id
                name
              }
              parent {
                id
                label {
                  id
                  name
                }
              }
            }
          }
        }
        label {
          id
          name
        }
      }
    }
  }
`;

export const DISPLAY_LEAVES = gql`
  query displayLeaves($page: Int, $size: Int, $filter: LeafCategoryFilter) {
    category {
      displayLeaves(page: $page, size: $size, filter: $filter) {
        edges {
          node {
            id
            parent {
              id
              label {
                id
                name
              }
              parent {
                id
                label {
                  id
                  name
                }
                parent {
                  id
                  label {
                    id
                    name
                  }
                  parent {
                    id
                    label {
                      id
                      name
                    }
                  }
                }
              }
            }
            label {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const DISPLAYS = gql`
  query displays($page: Int, $size: Int, $filter: DisplayCategoryFilter) {
    category {
      displays(page: $page, size: $size, filter: $filter) {
        edges {
          node {
            id
            parent {
              id
              label {
                id
                name
              }
              parent {
                id
                label {
                  id
                  name
                }
                parent {
                  id
                  label {
                    id
                    name
                  }
                  parent {
                    id
                    label {
                      id
                      name
                    }
                  }
                }
              }
            }
            label {
              id
              name
            }
          }
        }
      }
    }
  }
`;
