import { Term } from '@/common/Term';
import { CommissionType, CommonState } from '@frientrip/domain';
import { InquiryTemplate, InquiryTemplateItem } from './inquiryTemplate';
import { CurrencyCode, SalesType } from '../enum';
import { CommissionParam } from '../param/productSaleInfoParam';

export interface Item {
  absentFee: CommissionParam;
  additionalInquires: InquiryTemplateItem[];
  canSale: boolean;
  commission: CommissionParam;
  daysOfExpiration: number;
  description: string;
  id: string;
  indexNo: number;
  inquiryTermplate: InquiryTemplate;
  quota: number;
  minimumQuota: number;
  maximumPurchasableCount: number;
  name: string;
  options: ItemOption[];
  price: Price;
  // product
  representative: boolean; // 대표 아이템 여부: 상품 대표가격 용
  saleTerm: Term;
  salesType: SalesType;
  scheduledBySale: boolean;
  status: ItemState;
  stockCount: number;
  ticketCount: number;
  type: ItemType;
  digitalContent?: DigitalContent;
}

export interface DigitalContent {
  id: string;
  uri: string;
}

export interface FripCommision {
  bias: number;
  charge: number;
  type: CommissionType;
}

interface ItemOption {
  id: string;
  level: number;
  name: string;
  title: string;
  // option: ProductOption
  status: CommonState;
}

export enum ItemState {
  OPENED = 'OPENED',
  SOLD_OUT = 'SOLD_OUT',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',

  TERMINATED = 'TERMINATED',
}

enum ItemType {
  DIGITAL = 'DIGITAL',
  FRIP = 'FRIP',
  GOODS = 'GOODS',
}

export interface Price {
  currencyCode: CurrencyCode;
  discountRate: number;
  retail: number; // 정상 가격
  sale: number; // 판매 가격
}
