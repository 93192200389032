









































































































































import ContentCard from '@/components/FripComponents/ContentCard.vue';
import Vue from 'vue';
import {
  HistoryItem,
  SubjectChangeType,
  History,
} from '../../model/Inspection';
import HtmlDiff from 'node-htmldiff';
import StandardCategoryPath from '@/components/Category/StandardCategoryPath.vue';
import DisplayCategoryPathContainer from '@/components/Category/DisplayCategoryPathContainer.vue';

interface Option {
  label: string;
  elementName: string;
  subjectName: string;
}

export default Vue.extend({
  components: {
    ContentCard,
    StandardCategoryPath,
    DisplayCategoryPathContainer,
  },
  props: {
    histories: Array,
  },
  data() {
    return {
      options: [
        {
          label: '타이틀',
          elementName: 'title',
          subjectName: 'Product',
        },
        {
          label: '난이도',
          elementName: 'difficulty',
          subjectName: 'Frip',
        },
        {
          label: '상품 상세',
          elementName: 'content',
          subjectName: 'ProductContent',
        },
        {
          label: '상품 사진',
          elementName: '',
          subjectName: 'HeaderContent',
        },
        {
          label: '수동예약확정',
          elementName: 'bookingConfirmationEnabled',
          subjectName: 'Frip',
        },
        {
          label: '커리큘럼 유형',
          elementName: 'curriculum.style',
          subjectName: 'Frip',
        },
        {
          label: '커리큘럼 상세',
          elementName: '',
          subjectName: 'CurriculumSectionItem',
        },
        {
          label: '커리큘럼',
          elementName: '',
          subjectName: 'CurriculumSection',
        },
        {
          label: '옵션',
          elementName: '',
          subjectName: 'ItemOption',
        },
        {
          label: '커리큘럼',
          elementName: '',
          subjectName: 'Item',
        },
        {
          label: '추천 연령',
          elementName: 'recommendedAge',
          subjectName: 'Frip',
        },
        {
          label: '수수료',
          elementName: 'commission',
          subjectName: 'Item',
        },
        {
          label: '키워드 목록',
          elementName: 'keywords',
          subjectName: 'Product',
        },
        {
          label: '미성년 구매 제한',
          elementName: 'underageRestricted',
          subjectName: 'Product',
        },
        {
          label: '판매기간',
          elementName: 'saleTerm',
          subjectName: 'Product',
        },
        {
          label: '일정',
          elementName: '',
          subjectName: 'Schedule',
        },
        {
          label: '전시 카테고리',
          elementName: '',
          subjectName: 'ProductCategory',
        },
        {
          label: '포함사항',
          elementName: '',
          subjectName: 'FripInclusion',
        },
        {
          label: '불포함사항',
          elementName: '',
          subjectName: 'FripExclusion',
        },
        {
          label: '준비물',
          elementName: '',
          subjectName: 'FripStaffToPrepare',
        },
        {
          label: '표준 카테고리',
          elementName: '',
          subjectName: 'ProductStandardCategory',
        },
        {
          label: '아이템 명',
          elementName: 'name',
          subjectName: 'Item',
        },
        {
          label: '장소명',
          elementName: 'label',
          subjectName: 'FripLocation',
        },
        {
          label: '노출채널',
          elementName: '',
          subjectName: 'ProductExposure',
        },
        {
          label: '상품 태그',
          elementName: '',
          subjectName: 'ProductTag',
        },
      ],
    };
  },
  computed: {
    historyItems(): HistoryItem[] {
      console.log(this.histories);
      const historyItems: HistoryItem[] = [];
      this.histories.map((history: any) => {
        history.items.map((item: HistoryItem) => {
          //일단 특정 값들의 변경사항만 알려주도록 수정
          if (
            this.isDisplayCategory(item) ||
            this.isProductTitle(item) ||
            this.isHeaderContent(item) ||
            this.isProductContent(item)
          ) {
            const founded = historyItems.find(
              old =>
                old.subjectName === item.subjectName &&
                item.element?.elementName === old.element?.elementName &&
                old.changeType === item.changeType
            );

            if (founded) {
              if (founded.changeType === SubjectChangeType.UPDATE) {
                if (
                  founded.element &&
                  item.element &&
                  founded.subjectId === item.subjectId
                ) {
                  founded.element.beforeValue = item.element.beforeValue;
                } else {
                  historyItems.push(item);
                }
              } else {
                historyItems.push(item);
              }
            } else {
              historyItems.push(item);
            }
          }
        });
      });
      console.log(historyItems);
      return historyItems;
    },
  },
  methods: {
    isDisplayCategory(item: HistoryItem) {
      return item.subjectName === 'ProductCategory';
    },
    isStandardCategory(item: HistoryItem) {
      return item.subjectName === 'ProductStandardCategory';
    },
    isProductTitle(item: HistoryItem) {
      return (
        item.element?.elementName === 'title' && item.subjectName === 'Product'
      );
    },
    isProductContent(item: HistoryItem) {
      return item.element?.elementName === 'content';
    },
    isHeaderContent(item: HistoryItem) {
      return item.subjectName === 'HeaderContent';
    },
    getHtmlDiff(before: string, after: string, className: string) {
      return HtmlDiff(before, after, className);
    },
    getChangeTypeLabel(item: HistoryItem) {
      let label = '';
      if (item.changeType === SubjectChangeType.ADD) {
        label = '추가';
      } else if (item.changeType === SubjectChangeType.REMOVE) {
        label = '삭제';
      } else if (item.changeType === SubjectChangeType.UPDATE) {
        label = '수정';
      }
      return label;
    },
    getBeforeValueByChangeType(item: HistoryItem) {
      let value = '';
      if (item.changeType === SubjectChangeType.ADD) {
        value = '-';
      } else if (item.changeType === SubjectChangeType.REMOVE) {
        if (item.subject?.__typename === 'Content') {
          value = item.subject?.uri;
        } else if (item.subjectName === 'FripInclusion') {
          value = item.subjectId;
        } else if (item.subjectName === 'FripExclusion') {
          value = item.subjectId;
        } else if (item.subjectName === 'FripStaffToPrepare') {
          value = item.subjectId;
        } else if (item.subjectName === 'ProductExposure') {
          value = item.subjectId;
        } else if (item.subjectName === 'ProductTag') {
          value = item.subjectId;
        } else {
          value = item.element?.beforeValue || '-';
        }
      } else if (item.changeType === SubjectChangeType.UPDATE) {
        value = item.element?.beforeValue || '-';
      }
      return value;
    },
    getValueByChangeType(item: HistoryItem) {
      let value = '';
      if (item.changeType === SubjectChangeType.ADD) {
        if (item.subject?.__typename === 'Content') {
          value = item.subject?.uri;
        } else if (item.subjectName === 'FripInclusion') {
          value = item.subjectId;
        } else if (item.subjectName === 'FripExclusion') {
          value = item.subjectId;
        } else if (item.subjectName === 'FripStaffToPrepare') {
          value = item.subjectId;
        } else if (item.subjectName === 'ProductExposure') {
          value = item.subjectId;
        } else if (item.subjectName === 'ProductTag') {
          value = item.subjectId;
        } else {
          value = item.element?.value || item.subjectValue || '';
        }
      } else if (item.changeType === SubjectChangeType.REMOVE) {
        value = '-';
      } else if (item.changeType === SubjectChangeType.UPDATE) {
        value = item.element?.value || '';
      }
      return value;
    },
    convertHistoryItemToLabel(item: HistoryItem, options: Option[]) {
      const option = options.find(
        option =>
          (option.elementName === item.element?.elementName ||
            (!item.element && option.elementName === '')) &&
          option.subjectName === item.subjectName
      );
      let label =
        option?.label || item.element?.elementName || item.subjectName;
      //   if (item.changeType === SubjectChangeType.ADD) {
      //     label += '(추가)';
      //   } else if (item.changeType === SubjectChangeType.REMOVE) {
      //     label += '(삭제)';
      //   } else if (item.changeType === SubjectChangeType.UPDATE) {
      //     label += '(수정)';
      //   }

      return label;
    },
  },
});
