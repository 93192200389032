











































































import Vue from 'vue';
import { CurriculumKind } from '@/domain/product/model/product/enum';

export default Vue.extend({
  components: {},
  props: {
    value: {
      type: Object,
    },
    kind: {
      type: String,
    },
    index: {
      type: Number,
    },
    curriculumStyle: {
      type: String,
    },
  },
  data() {
    return {
      // TODO: 직접 value를 넣으면 입력이 안된다.. 그래서 deep copy 함
      curriculum: JSON.parse(JSON.stringify(this.value)),
      hasEtc: this.value.etc !== '',
    };
  },
  computed: {
    displayTitle(): string {
      if (this.kind === CurriculumKind.CURRICULUM) {
        return '커리큘럼';
      } else if (this.kind === CurriculumKind.SCHEDULE) {
        return '스케줄';
      } else {
        return '';
      }
    },
    title(): string {
      return this.curriculum.title === ''
        ? `${this.displayTitle} 타이틀을 입력해주세요`
        : this.curriculum.title;
    },
  },
  methods: {
    colsByIndex(index: number): number {
      return index === 0 ? 2 : 1;
    },
  },
});
