












































import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import { ItemParam } from '../../model/product/param/productSaleInfoParam';
import { getItemStatusLabel } from '../../util/getItemStatusLabel';
import { ItemState } from '../../model/product/enum';
import { StatusLabel } from '@/common/model/statusLabel';

export default Vue.extend({
  name: 'ItemSelectList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    items: {
      type: Array,
    },
    itemParamIds: {
      type: Array,
    },
  },
  data(): {
    rows: ItemParam[];
  } {
    return {
      rows: this.items as ItemParam[],
    };
  },
  computed: {
    selectedItems(): string[] {
      return this.rows.filter(row => row.checked).map(row => row.paramId);
    },
  },
  watch: {
    items: {
      immediate: true,
      deep: true,
      handler(newItems) {
        this.rows = newItems;
      },
    },
  },
  created() {
    this.rows.forEach(row => {
      if (this.itemParamIds.includes(row.paramId)) {
        row.checked = true;
      } else {
        row.checked = false;
      }
    });
  },
  methods: {
    applyItems() {
      this.$emit('update:itemParamIds', this.selectedItems);
      this.closeListModal();
    },
    selectRow(value: boolean, paramId: string) {
      const index = this.rows.findIndex(row => row.paramId === paramId);
      if (index >= 0) {
        const newRow = this.rows[index];
        newRow.checked = value;
        this.rows.splice(index, 1, newRow);
      }
    },
    itemStatusLabel(status: ItemState): StatusLabel {
      return getItemStatusLabel(status);
    },
    closeListModal() {
      this.$emit('close');
    },
  },
});
