import { Term } from '@/common/Term';
import { Content } from '@/domain/qna/model/serviceQna';
import { Commission, CommonState } from '@frientrip/domain';
import { Frip } from './frip';
import { InquiryTemplate } from './inquiryTemplate';
import { Item } from './item';
import { Price } from '../../price';
import { Merchandiser } from '../../merchandiser';
import { Tag } from './tag';
import { RefundPolicy } from './refundPolicy';
import { StandardCategory } from '@/common/model/Category';
import { InventoryTargetType, SalesType } from '../enum';
import { User } from '@/domain/user/models/user';
import {
  InspectionItemState,
  InspectionMode,
  InspectionState,
} from '@/domain/inspection/model/enum';
import { FripCommission } from '@/common/model/fripCommission';

export interface Product {
  attributes: ProductAttribute[];
  catchphrase: string;
  cancelingRestricted: boolean;
  categories: ProductCategory[];
  contents: ProductContent[];
  exposures: ExposedChannel[];
  externalProvisions: ExternalProvision[];
  firstOpenedAt?: number | null;
  frip: Frip;
  grossPurchaseLimitation: GrossPurchasableLimitation;
  headerContents: ProductHeaderContent[];
  host: Host;
  id: string;
  inquiryTemplate: InquiryTemplate;
  items: Item[];
  keywords: string[];
  kind: ProductKind;
  lastInspection?: Inspection;
  manager: Merchandiser | null;
  maximumPurchasableCount: number;
  notice: string;
  options: ProductOption[];
  representativePrice: Price;
  saleTerm: Term;
  staff: Merchandiser;
  // TODO: StandardCategory 타입 선언
  standardCategories: StandardCategory[];
  status: ProductState;
  tags: Tag[];
  title: string;
  underageRestricted: boolean;
  unverifiedUserRestricted: boolean;
  refundPolicy: RefundPolicy;
  deliveryInfo: ProductDeliveryInfo;
  inventoryTargetOptionsLevel: number;
  inventoryTargetType: InventoryTargetType;
  // TODO: wishInfo 추가
  // TODO: reviewInfo 추가
}

export interface ProductConnection {
  edges: ProductEdge[];
  totalCount: number;
}

export interface ProductEdge {
  node: Product;
}

// 상품 속성
export interface ProductAttribute {
  description: string;
  id: string;
  name: string;
  status: number;
  kind: ProductKind;
}

// 상품 카테고리
interface ProductCategory {
  displayCategory: DisplayCategory;
  representative: boolean;
}

interface ProductContent {
  content: string;
  id: string;
  status: number;
  style: string;
  type: string;
}

export interface ExposedChannel {
  description: string;
  id: string;
  name: string;
  status: number;
}

// 외부 연동 정보
interface ExternalProvision {
  id: string;
  name: string;
  status: number;
}

interface DisplayCategory {
  id: string;
}

export interface ProductHeaderContent {
  content: HeaderContent;
  id: string;
  style: ProductHeaderContentStyle;
}

enum ProductHeaderContentStyle {
  SQUARE,
  STANDARD,
}

export interface HeaderContent {
  id: string;
  type: string;
  width: number;
  height: number;
  uri: string;
  thumbnail?: string;
}

export enum ProductState {
  APPLIED = 'APPLIED',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
  EDITING = 'EDITING',
  INSPECTING = 'INSPECTING',
  SALE = 'SALE',
  PAUSED = 'PAUSED',
  READY = 'READY',
  REJECTED = 'REJECTED',
  SOLD_OUT = 'SOLD_OUT',
  TERMINATED = 'TERMINATED',
  SUSPENDED = 'SUSPENDED',
}

export interface ProductKindInfo {
  label: string;
  description: string;
  standardCategory: StandardCategory;
  value: ProductKind;
}

export enum ProductKind {
  GOODS = 'GOODS',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  EVENT = 'EVENT',
}

// TODO: 분리 필요
export interface Host {
  id: string;
  name: string;
  commission: HostCommission;
}

export interface HostCommission {
  salesType: SalesType;
  default: FripCommission;
  absent: FripCommission;
}

export interface ProductOption {
  id?: string;
  level: number;
  names: string[];
  status: CommonState;
  title: string;
}

interface ProductDeliveryInfo {
  cost: Money;
}

// TODO: 분리 필요
interface Money {
  currencyCode: string;
  value: number;
}

interface GrossPurchasableLimitation {
  count: number;
}

export interface Inspection {
  completedAt?: number;
  createdAt?: number;
  flowVersion?: InspectionFlowVersion;
  host?: Host;
  id: string;
  items: InspectionItem[];
  mode?: InspectionMode;
  status?: InspectionState;
}

export enum InspectionFlowVersion {
  V1 = 'V1',
}

export interface InspectionItem {
  comment: string;
  id: string;
  part: string;
  reasons: string;
  status: InspectionItemState;
  worker: User;
}
