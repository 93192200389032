




































































import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import Instant from '@/components/Labels/Instant.vue';

export default Vue.extend({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Instant,
  },
  props: {
    product: {
      type: Object,
    },
  },
});
