import { ApolloClient, NormalizedCacheObject } from 'apollo-boost';
import {
  INSPECTION_APPROVE_MUTATION,
  INSPECTION_REJECT_MUTATION,
} from '../graphql/mutation';
import { InspectionState, InspectionMode } from '../model/enum';

export class InspectionService {
  static getStateLabels(): InspectionStateInfo[] {
    return [
      {
        value: InspectionState.APPLIED,
        label: '검수 신청',
      },
      {
        value: InspectionState.INSPECTING,
        label: '검수 중',
      },
      {
        value: InspectionState.PAUSED,
        label: '검수 중지',
      },
      {
        value: InspectionState.APPROVED,
        label: '검수 승인',
      },
      {
        value: InspectionState.CANCELED,
        label: '검수 취소',
      },
      {
        value: InspectionState.REJECTED,
        label: '검수 반려',
      },
    ];
  }

  static getModeLabelsIn(): InspectionModeInfo[] {
    return [
      {
        value: InspectionMode.AFTER,
        label: '수정',
      },
      {
        value: InspectionMode.BEFORE,
        label: '신규',
      },
    ];
  }

  constructor(private readonly apollo: ApolloClient<NormalizedCacheObject>) {}

  public async approve(inspectionId: string, part: string): Promise<void> {
    await this.apollo.mutate({
      mutation: INSPECTION_APPROVE_MUTATION,
      variables: {
        inspectionId: inspectionId,
        param: {
          comment: '',
          part: part,
          reasons: [],
        },
      },
    });
  }

  public async reject(
    inspectionId: string,
    param: InspectionParamInput
  ): Promise<void> {
    await this.apollo.mutate({
      mutation: INSPECTION_REJECT_MUTATION,
      variables: {
        inspectionId: inspectionId,
        param: param,
      },
    });
  }
}

export interface InspectionStateInfo {
  value: InspectionState;
  label: string;
}

export interface InspectionParamInput {
  comment: string;
  part: string;
  reasons: string[];
}

export interface InspectionModeInfo {
  value: InspectionMode;
  label: string;
}
