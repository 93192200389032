import { MessageType } from 'element-ui/types/message';
import Vue from 'vue';

export const NotificationMixin = Vue.extend({
  methods: {
    showSuccessMessage(title: string, message: string) {
      this.$notify({
        title: title,
        message: message,
        type: 'success',
      });
    },
    showErrorMessage(title: string, error: any) {
      this.$notify({
        title: title,
        message: error.message.replace('GraphQL error:', ''),
        type: 'warning',
      });
    },
    showMessage(title: string, message: string, type?: MessageType) {
      this.$notify({
        title: title,
        message: message,
        type: type || 'success',
      });
    },
  },
});
