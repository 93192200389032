var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('inline-text',{attrs:{"label":"마지막 검수 상태"}},[_c('inspection-status-label',{attrs:{"status":_vm.product.lastInspection.status}})],1),_c('inline-text',{attrs:{"label":"마지막 검수 신청일"}},[(_vm.product.lastInspection.createdAt)?_c('instant',{attrs:{"at":_vm.product.lastInspection.createdAt}}):_vm._e()],1),_c('inline-text',{attrs:{"label":"마지막 검수 완료일"}},[(_vm.product.lastInspection.completedAt)?_c('instant',{attrs:{"at":_vm.product.lastInspection.completedAt}}):_c('div',[_vm._v("없음")])],1),_c('inline-text',{attrs:{"label":"마지막 검수 구분"}},[_c('inspection-mode-label',{attrs:{"mode":_vm.product.lastInspection.mode}})],1),_c('el-table',{attrs:{"data":_vm.inspections}},[_c('el-table-column',{attrs:{"type":"expand","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-table',{attrs:{"data":row.items}},[_c('el-table-column',{attrs:{"label":"id","prop":"id"}}),_c('el-table-column',{attrs:{"label":"코멘트","prop":"comment"}}),_c('el-table-column',{attrs:{"label":"파트","prop":"part"}}),_c('el-table-column',{attrs:{"label":"상태","prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('inspection-item-status-label',{attrs:{"status":row.status}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"반려 사유","prop":"reasons"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.reasons.map(function (reason) { return reason.name; }).join(','))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"담당자","prop":"worker.nickname"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"검수 id","prop":"id"}}),_c('el-table-column',{attrs:{"label":"신청일"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.createdAt)?_c('instant',{attrs:{"at":row.createdAt}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"마감일"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.completedAt)?_c('instant',{attrs:{"at":row.completedAt}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"모드","prop":"mode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('inspection-mode-label',{attrs:{"mode":row.mode}})]}}])}),_c('el-table-column',{attrs:{"label":"상태","prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('inspection-status-label',{attrs:{"status":row.status}})]}}])},[_vm._v(" >")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }