






































































































































import { ContainerMixin } from '@/common/mixin/containerMixin';
import { PRODUCT_VIEW_QUERY, REASONS_OF_REJECTION } from '../graphql/query';
import { InspectionService } from '../service/InspectionService';
import { apolloClient } from '@/apolloClient';
import { ReasonForInspectionRejection } from '../model/Inspection';
import { ApolloError } from 'apollo-client';
// import {
//   CurriculumKind,
//   CurriculumStyle,
//   InventoryTargetType,
//   LevelOfDifficulty,
//   ProductKind
// } from "@/domain/product/model/product/enum";
// import {InspectionState} from "@/domain/inspection/model/enum";
// import {
//   CurriculumKind,
//   CurriculumStyle,
//   InventoryTargetType,
//   LevelOfDifficulty,
//   ProductKind
// } from "@/domain/product/model/product/enum";
// import {InspectionState} from "@/domain/inspection/model/enum";
// import ProductItemListForm from "@/domain/inspection/views/form/ProductItemListForm.vue";
const ACCEPT = 'ACCEPT';
const REJECT = 'REJECT';

const service = new InspectionService(apolloClient);
export default ContainerMixin.extend({
  name: 'InspectionPopup',
  mixins: [ContainerMixin],
  // components: {
  //   ProductItemListForm,
  // },
  // props: ["productValue", "hostValue"],
  data() {
    return {
      productId: this.$route.params.id || '',
      isFrip: true,
      opened: false,
      inspectionId: '',
      inspectionPart: '',
      action: ACCEPT,
      selectedReasonIds: [] as string[],
      reasons: [],
      comment: '',
      options: [
        { text: '승인', value: ACCEPT },
        { text: '반려', value: REJECT },
      ],
      product: {
        host: {
          id: '',
          name: '',
          super: '',
          commission: {
            absent: {
              charge: '',
            },
            default: {
              charge: '',
            },
          },
        },
        items: [],
        options: [
          {
            title: '',
            names: [],
          },
        ],
        kind: '',
      },
    };
  },
  computed: {
    commentRequired(): boolean {
      return this.reasons.some((i: ReasonForInspectionRejection) =>
        this.selectedReasonIds.includes(i.id) ? i.requiredComment : false
      );
    },
  },
  methods: {
    open(inspectionId: string, part: string): void {
      this.inspectionId = inspectionId;
      this.inspectionPart = part;
      this.action = ACCEPT;
      this.selectedReasonIds = [];
      this.comment = '';
      this.opened = true;
    },
    async clickOk() {
      console.log('clicked');
      if (!this.action) {
        alert('승인 또는 반려를 선택해 주세요.');
        return;
      }
      if (this.action == REJECT) {
        if (this.selectedReasonIds.length == 0) {
          alert('반려 사유를 한개 이상 선택해야 합니다.');
          return;
        }
        if (this.comment.trim().length == 0 && this.commentRequired) {
          alert('자세한 반려 사유를 작성해주세요.');
          return;
        }
      }
      try {
        if (this.action === 'ACCEPT') {
          await service.approve(this.inspectionId, this.inspectionPart);
          this.showSuccessMessage('검수 성공', '검수 승인되었습니다.');
        } else {
          await service.reject(this.inspectionId, {
            part: this.inspectionPart,
            comment: this.comment,
            reasons: this.selectedReasonIds,
          });
          this.showSuccessMessage('검수 성공', '검수 반려되었습니다.');
        }
        this.$emit('success');
        this.opened = false;
      } catch (error) {
        this.showErrorAlert(error, '검수 실패');
      }
    },
  },
  apollo: {
    reasons: {
      query: REASONS_OF_REJECTION,
      update: data =>
        data.product.reasonsForRejection.map(
          (i: ReasonForInspectionRejection) => {
            return {
              value: i.id,
              text: i.name,
            };
          }
        ),
    },
    product: {
      query: PRODUCT_VIEW_QUERY,
      variables(): { productId: string } {
        return {
          productId: this.productId,
        };
      },
      skip(): boolean {
        return this.$route.path === '/product/create';
      },
      update(data) {
        this.isFrip = this.product.kind !== 'GOODS';
        console.log(data);
        return data.product.product;
      },
      error(e: ApolloError) {
        console.error(e);
      },
    },
  },
});
